
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
// Grid containers
//
// Define the maximum width of `.container` for different screen sizes. 
$container-max-widths: (
  sm: 940px,
  md: 950px,
  lg: 960px, 
  xl: 1140px
); 

@include _assert-ascending($container-max-widths, "$container-max-widths");
$theme-colors: ( 
  "blue": #007bff, 
  "indigo": #6610f2, 
  "purple": #6f42c1, 
  "pink": #e83e8c, 
  "red": #FC5F7D, 
  "orange": #f47c0b, 
  "yellow": #ffc107, 
  "green": #28a745, 
  "teal": #20c997, 
  "cyan": #17a2b8, 
  "white": #fff, 
  "gray": #6c757d,
  "gray-light": #9B9EB8,
  "gray-dark": #485563,  
  "primary": #00B0EF, 
  "main": #3a3e63,
  "main-light": lighten(#3a3e63, 40%),
  //"secondary": #E4EBF2,
  "secondary": darken(#F6F9FC, 7%),
  "success": #13B272,
  "info": #01bfa5, 
  "warning": #ff9910, 
  "danger": #FC5F7D,
  "light": #F6F9FC,
  "dark": #485563,
  "dark-blue": #4E5381,
  "active": #80C342,
  "inactive": #75799D,
  "input": #ced4da,
  "disabled": #e9ecef,
);
$primary: #00B0EF;
//$secondary: #E4EBF2;
$secondary: darken(#F6F9FC, 7%);
$success: #13B272;
$info: #01bfa5;
$warning: #ff9910;
$danger: #FC5F7D;
$light: #F6F9FC;
$dark: #485563;
$inactive: #75799D;
$input: #ced4da;
$disabled: #e9ecef;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

//$body-bg: #E4EBF2;
$body-bg: #E4EBF2;
//$content-bg: #E4EBF2;
$main-bg: #4e5381;
$side-menu-bg: #f6f9fc; 
//$text-main: #4e5381;
$text-main: #3a3e63;
$text-main-light: lighten($text-main, 40%);
$grid-gutter-width: 20px;
$form-grid-gutter-width: 15px;
$transition-base:all .2s ease-in-out;
//btn
$btn-padding-x:.875rem;
$btn-padding-y:.375rem;

$btn-padding-x-lg :1rem;
$btn-padding-y-lg :.5rem;

$btn-padding-x-sm :.5rem; 
$btn-padding-y-sm :.25rem;

//btn-icon
//$btn-icon-padding-x: $btn-padding-x * 4;
$btn-icon-padding-x: $btn-padding-x * 3.4;
$btn-icon-padding-x-lg: $btn-padding-x-lg * 4;
$btn-icon-padding-x-sm: $btn-padding-x-sm * 5;

$input-placeholder-color: lighten($text-main, 30%);  
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $white ;
$text-muted: lighten($text-main, 20%);
$border-color: $gray-300;

$enable-rounded:false;

$enable-print-styles: true;
$print-page-size: a4;

// font size
$font-size-base: 0.875rem;
$btn-font-size: 0.875rem;
$input-font-size: 0.875rem;
$custom-select-font-size: 0.875rem;
$input-height: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border + 3});
$custom-select-height: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border + 3});